<template>
  <div>
    <article>
      <h3 class="center">访客模式（基本业务功能模式）说明</h3>
      <section>
        <p>
          {{
            appName
          }}APP属于音乐电台类应用程序，基本业务功能为音频播放。如您拒绝同意本政策，我们将尊重您的选择权，为您提供音频播放的基本业务功能。您可通过“访客模式”进入。在基本业务功能模式下，我们不会收集可识别您个人的身份信息，但为保障音频播放基本功能的安全运行，我们需要收集您的必要个人信息。具体包括：
        </p>
        <p>
          为了实现内容播放，我们需要通过北京火山引擎科技有限公司的火山点播SDK，收集操作系统api版本、CPU信息、IDFV、运营商信息、设备品牌、设备型号、操作系统、系统时区、应用版本、应用发布渠道。SDK隐私政策链接：<a
            href="https://www.volcengine.com/docs/4/65789"
            target="_blank"
            >https://www.volcengine.com/docs/4/65789</a
          >
        </p>
      </section>
      <section>
        <p>请您放心，在基本业务功能模式下，我们无法将这些信息与您的身份结合或用于反映您的活动情况。</p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音' }],
          ['kuaiyintingge', { appName: '快音听歌版' }],
          ['kuaiyinjisu', { appName: '快音极速版' }],
          ['kuaiyinyue', { appName: '快音悦' }],
          ['kuaiyinfm', { appName: '快音FM' }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      document.title = '访客模式（基本业务功能模式）说明';
      let app_name = 'kuaiyin';

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).appName;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  .center {
    text-align: center;
  }
  .sign {
    margin: 50px 0;
    text-align: right;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
